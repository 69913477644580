import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import {
  TextInput,
  SimpleForm,
  required,
  useNotify,
  SaveContextProvider,
  useGetIdentity,
  useRedirect,
  Toolbar,
  SaveButton,
  AutocompleteInput,
  ReferenceInput, useRefresh, SelectInput, useRecordContext,
} from "react-admin";
import { userApi } from "../providers/env";

const ProfileContext = createContext({
  profileVersion: 0,
  refreshProfile: () => {},
});

export const ProfileProvider = ({ children }: { children: any }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () => {
        setProfileVersion((currentVersion) => currentVersion + 1);
      },
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

const CustomToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const filterToQuery = (searchText: any) => ({ phone: searchText });

const CustomerField = () => {
      return (
          <ReferenceInput source="customer" reference="customers" alwaysOn={true} debounceDelay={500}>
            <AutocompleteInput
                isOptionEqualToValue={(option, value) => option.value === value.value}
                label="customer" optionText="phone" filterToQuery={filterToQuery} />
          </ReferenceInput>
    );
}


export const ProfileEdit = ({ ...props }) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const { refreshProfile, profileVersion } = useProfile();
  const { isLoading: isUserIdentityLoading, data } = useGetIdentity();

  if (!isUserIdentityLoading && !data?.email) {
    redirect("/login");
  }

  const handleSave = useCallback(
    (values) => {
      userApi
        .usersPatchCurrentUserUsersMePatch({ userUpdate: values })
        .then(() => {
          notify("Your profile has been updated", { type: "info" });
        })
        .catch((e) => {
          notify(
            e.response?.data?.detail || "Unknown error, please try again later",
            { type: "error" }
          );
        });
    },
    [notify]
  );

  if (isUserIdentityLoading) {
    return null;
  }

  return (
    <SaveContextProvider
      value={{ save: handleSave }}
      key={profileVersion}
    >
      <SimpleForm record={data ? data : {}} toolbar={<CustomToolbar />}>
        <TextInput source="id" disabled />
        <TextInput source="email" validate={required()} />
        <CustomerField {...props} />
      </SimpleForm>
    </SaveContextProvider>
  );
};
