import {
    Create,
    Datagrid, DateField, DateTimeInput,
    Edit, FileField, FileInput,
    List, SaveButton, SelectInput,
    SimpleForm,
    TextField,
    TextInput, Toolbar, useGetList, useRecordContext, useUpdate
} from "react-admin";
import React, {useEffect, useState} from "react";
import {Grid} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import {useController, useFormContext, useFormState} from "react-hook-form";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';


const TemplatesField = () => {
    const {data, isLoading} = useGetList(
        'templates',
        {pagination: {page: 1, perPage: 100}}
    );
    return (
        <SelectInput
            source="template"
            choices={data}
            optionText="title"
            optionValue="id"
            isLoading={isLoading}
            fullWidth={true}
            required={true}
        />
    );
}

const FiltersField = () => {
    const {data, isLoading} = useGetList(
        'filters',
        {pagination: {page: 1, perPage: 100}}
    );
    return (
        <SelectInput
            source="filter"
            choices={data}
            optionText="title"
            optionValue="id"
            isLoading={isLoading}
            fullWidth={true}
        />
    );
}


const ChooseCustomersDownloadField = () => {

    const record = useRecordContext();
    const {setValue} = useFormContext();
    const [downloadType, setDownloadType] = React.useState(
        record !== undefined ? record.download_type : "");
    const downloadTypeField = useController({ name: 'download_type', defaultValue: '' });

    const handleChange = (event: SelectChangeEvent) => {
        const newDownloadType = event.target.value as string;
        if (!!newDownloadType) {
            setValue("download_type", newDownloadType);
            setDownloadType(newDownloadType);
        }
    };

    return (
        <React.Fragment>
            <FormControl fullWidth required>
                <InputLabel id="download-input-label-id">Тип загрузки пользователей</InputLabel>
                <Select
                    {...downloadTypeField.field}
                    labelId="download-select-label-id"
                    id="download-select-id"
                    value={downloadType ?? ""}
                    name="download_type"
                    label="Type"
                    onChange={handleChange}
                >
                    <MenuItem value={"filter"}>Загрузка данных через фильтр</MenuItem>
                    <MenuItem value={"file"}>Загрузка данных через файл</MenuItem>
                </Select>
                {
                    downloadType === "file" ?
                        <FileInput source="subscribers" multiple>
                            <FileField source="src" title="Subscribers"/>
                        </FileInput> :
                        downloadType === "filter" &&
                        <FiltersField/>
                }
            </FormControl>
        </React.Fragment>
    );
}


export const CampaignList = (props: any) => (
    <List {...props} filters={[]} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="title"/>
            <TextField source="recipients_count"/>
            <TextField source="notifications_sent"/>
            <TextField source="notifications_not_sent"/>
            <TextField source="notifications_viewed"/>
            <TextField source="notifications_read"/>
            <DateField source="datetime_start" showTime={true} label={"Datetime start (UTC)"}/>
        </Datagrid>
    </List>
);

const CampaignToolbar = () => {
    const [update] = useUpdate();
    const {getValues} = useFormContext();
    const [isLocked, setIsLocked] = useState(false);

    const handleSelfTest = (e: any) => {
        setIsLocked(true)
        e.preventDefault(); // necessary to prevent default SaveButton submit logic
        const data = getValues();
        update(
            'campaigns/selftest',
            {id: data.id, data: data},
            {
                onSuccess: () => {
                    setTimeout(() => setIsLocked(false), 3000);
                }
            }
        );
    };

    const handleClickRunCampaign = (e: any) => {
        e.preventDefault();
        const data = getValues();
        data.status = 'run'
        update(
            'campaigns',
            {data: data, id: data.id},
        );
    }
    const values = getValues()
    const formData = useFormState()
    const enableCondition = values.status === "new" && !formData.isDirty && values.recipients_count > 0
    return (
        <Toolbar>
            <SaveButton/>
            <SaveButton label="Send to myself" type="button" onClick={handleSelfTest} alwaysEnable={!isLocked} />
            <SaveButton label="Run Campaign" type="button" style={{marginLeft: "auto"}}
                        onClick={handleClickRunCampaign} alwaysEnable={enableCondition}
                        disabled={formData.isDirty}
                        icon={<RocketLaunchIcon/>}/>
        </Toolbar>
    );
};

export const CampaignEdit = (props: any) => {
    return <Edit {...props}>
        <SimpleForm toolbar={<CampaignToolbar/>}>
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <TextInput name="title" source="title" fullWidth required />
                    <SelectInput name="status" source="status" choices={statusChoicesList} disabled />
                    <TemplatesField/>
                    <ChooseCustomersDownloadField/>
                    <TextInput name="recipients_count" source="recipients_count" disabled/>
                    <br/>
                    <TextInput name="notifications_sent" source="notifications_sent" disabled/>
                    <br/>
                    <TextInput name="notifications_not_sent" source="notifications_not_sent" disabled/>
                    <br/>
                    <TextInput name="notifications_viewed" source="notifications_viewed" disabled/>
                    <br/>
                    <TextInput name="read_notifications" source="notifications_read" disabled/>
                    <br/>
                    <DateTimeInput name="datetime_start" source="datetime_start" disabled/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
};


const statusChoicesList = [
    {id: "new", name: "New"},
    {id: "run", name: "Run"},
    {id: "in_progress", name: "In Progress", disabled: true},
    {id: "done", name: "Done", disabled: true},
]


export const CampaignCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <TextInput name="title" source="title" fullWidth required/>
                        <SelectInput name="status" source="status" defaultValue="new" choices={statusChoicesList} sx={{display: "none"}} />
                        <TemplatesField/>
                        <br/>
                        <ChooseCustomersDownloadField/>
                        <TextInput defaultValue="0" name="recipients_count" source="recipients_count" sx={{display: "none"}} />
                        <br/>
                        <TextInput defaultValue="0" name="notifications_sent" source="notifications_sent" sx={{display: "none"}} />
                        <br/>
                        <TextInput defaultValue="0" name="notifications_not_sent" source="notifications_not_sent" sx={{display: "none"}} />
                        <br/>
                        <TextInput defaultValue="0" name="notifications_viewed" source="notifications_viewed" sx={{display: "none"}} />
                        <br/>
                        <TextInput defaultValue="0" name="notifications_read" source="notifications_read" sx={{display: "none"}} />
                        <br/>
                        <DateTimeInput name="datetime_start" source="datetime_start" sx={{display: "none"}} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};
