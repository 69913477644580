import {Datagrid, List, NumberInput, TextField, TextInput} from "react-admin";
import React from "react";


const postFilters = [
    <TextInput name="customer_id" label="Search by customer_id" source="customer_id" alwaysOn/>,
];


export const PushNotificationList = (props: any) => (
    <List {...props} filters={postFilters} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="title" readOnly/>
            <TextField source="customer_id" readOnly/>
            <TextField source="datetime" readOnly/>
            <TextField source="read" readOnly/>
            <TextField source="short_body" readOnly/>
            <TextField source="campaign" readOnly/>
            <TextField source="category" readOnly/>
            <TextField source="context" readOnly/>
        </Datagrid>
    </List>
);
