/* tslint:disable */
/* eslint-disable */
/**
 * Communication API
 * Communication Platform API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorModel } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { UserRead } from '../models';
// @ts-ignore
import { UserUpdate } from '../models';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Confirmation
         * @param {any} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationUsersConfirmationTokenGet: async (token: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('confirmationUsersConfirmationTokenGet', 'token', token)
            const localVarPath = `/users/confirmation/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Users:Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentUserUsersMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Users:Delete User
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUserUsersIdDelete: async (id: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersDeleteUserUsersIdDelete', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Users:Patch Current User
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPatchCurrentUserUsersMePatch: async (userUpdate: UserUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userUpdate' is not null or undefined
            assertParamExists('usersPatchCurrentUserUsersMePatch', 'userUpdate', userUpdate)
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Users:Patch User
         * @param {any} id 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPatchUserUsersIdPatch: async (id: any, userUpdate: UserUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersPatchUserUsersIdPatch', 'id', id)
            // verify required parameter 'userUpdate' is not null or undefined
            assertParamExists('usersPatchUserUsersIdPatch', 'userUpdate', userUpdate)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Users:User
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserUsersIdGet: async (id: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersUserUsersIdGet', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Confirmation
         * @param {any} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmationUsersConfirmationTokenGet(token: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmationUsersConfirmationTokenGet(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Users:Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCurrentUserUsersMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCurrentUserUsersMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Users:Delete User
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDeleteUserUsersIdDelete(id: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDeleteUserUsersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Users:Patch Current User
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPatchCurrentUserUsersMePatch(userUpdate: UserUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPatchCurrentUserUsersMePatch(userUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Users:Patch User
         * @param {any} id 
         * @param {UserUpdate} userUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPatchUserUsersIdPatch(id: any, userUpdate: UserUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPatchUserUsersIdPatch(id, userUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Users:User
         * @param {any} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserUsersIdGet(id: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserUsersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Confirmation
         * @param {UsersApiConfirmationUsersConfirmationTokenGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationUsersConfirmationTokenGet(requestParameters: UsersApiConfirmationUsersConfirmationTokenGetRequest, options?: AxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.confirmationUsersConfirmationTokenGet(requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Users:Current User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentUserUsersMeGet(options?: AxiosRequestConfig): AxiosPromise<UserRead> {
            return localVarFp.usersCurrentUserUsersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Users:Delete User
         * @param {UsersApiUsersDeleteUserUsersIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUserUsersIdDelete(requestParameters: UsersApiUsersDeleteUserUsersIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.usersDeleteUserUsersIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Users:Patch Current User
         * @param {UsersApiUsersPatchCurrentUserUsersMePatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPatchCurrentUserUsersMePatch(requestParameters: UsersApiUsersPatchCurrentUserUsersMePatchRequest, options?: AxiosRequestConfig): AxiosPromise<UserRead> {
            return localVarFp.usersPatchCurrentUserUsersMePatch(requestParameters.userUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Users:Patch User
         * @param {UsersApiUsersPatchUserUsersIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPatchUserUsersIdPatch(requestParameters: UsersApiUsersPatchUserUsersIdPatchRequest, options?: AxiosRequestConfig): AxiosPromise<UserRead> {
            return localVarFp.usersPatchUserUsersIdPatch(requestParameters.id, requestParameters.userUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Users:User
         * @param {UsersApiUsersUserUsersIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserUsersIdGet(requestParameters: UsersApiUsersUserUsersIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<UserRead> {
            return localVarFp.usersUserUsersIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for confirmationUsersConfirmationTokenGet operation in UsersApi.
 * @export
 * @interface UsersApiConfirmationUsersConfirmationTokenGetRequest
 */
export interface UsersApiConfirmationUsersConfirmationTokenGetRequest {
    /**
     * 
     * @type {any}
     * @memberof UsersApiConfirmationUsersConfirmationTokenGet
     */
    readonly token: any
}

/**
 * Request parameters for usersDeleteUserUsersIdDelete operation in UsersApi.
 * @export
 * @interface UsersApiUsersDeleteUserUsersIdDeleteRequest
 */
export interface UsersApiUsersDeleteUserUsersIdDeleteRequest {
    /**
     * 
     * @type {any}
     * @memberof UsersApiUsersDeleteUserUsersIdDelete
     */
    readonly id: any
}

/**
 * Request parameters for usersPatchCurrentUserUsersMePatch operation in UsersApi.
 * @export
 * @interface UsersApiUsersPatchCurrentUserUsersMePatchRequest
 */
export interface UsersApiUsersPatchCurrentUserUsersMePatchRequest {
    /**
     * 
     * @type {UserUpdate}
     * @memberof UsersApiUsersPatchCurrentUserUsersMePatch
     */
    readonly userUpdate: UserUpdate
}

/**
 * Request parameters for usersPatchUserUsersIdPatch operation in UsersApi.
 * @export
 * @interface UsersApiUsersPatchUserUsersIdPatchRequest
 */
export interface UsersApiUsersPatchUserUsersIdPatchRequest {
    /**
     * 
     * @type {any}
     * @memberof UsersApiUsersPatchUserUsersIdPatch
     */
    readonly id: any

    /**
     * 
     * @type {UserUpdate}
     * @memberof UsersApiUsersPatchUserUsersIdPatch
     */
    readonly userUpdate: UserUpdate
}

/**
 * Request parameters for usersUserUsersIdGet operation in UsersApi.
 * @export
 * @interface UsersApiUsersUserUsersIdGetRequest
 */
export interface UsersApiUsersUserUsersIdGetRequest {
    /**
     * 
     * @type {any}
     * @memberof UsersApiUsersUserUsersIdGet
     */
    readonly id: any
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Confirmation
     * @param {UsersApiConfirmationUsersConfirmationTokenGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public confirmationUsersConfirmationTokenGet(requestParameters: UsersApiConfirmationUsersConfirmationTokenGetRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).confirmationUsersConfirmationTokenGet(requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Users:Current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCurrentUserUsersMeGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCurrentUserUsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Users:Delete User
     * @param {UsersApiUsersDeleteUserUsersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersDeleteUserUsersIdDelete(requestParameters: UsersApiUsersDeleteUserUsersIdDeleteRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersDeleteUserUsersIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Users:Patch Current User
     * @param {UsersApiUsersPatchCurrentUserUsersMePatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPatchCurrentUserUsersMePatch(requestParameters: UsersApiUsersPatchCurrentUserUsersMePatchRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPatchCurrentUserUsersMePatch(requestParameters.userUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Users:Patch User
     * @param {UsersApiUsersPatchUserUsersIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPatchUserUsersIdPatch(requestParameters: UsersApiUsersPatchUserUsersIdPatchRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPatchUserUsersIdPatch(requestParameters.id, requestParameters.userUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Users:User
     * @param {UsersApiUsersUserUsersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserUsersIdGet(requestParameters: UsersApiUsersUserUsersIdGetRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserUsersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

