import {addRefreshAuthToAuthProvider, UserIdentity} from "react-admin";
import {authApi, authRefreshApi, userApi} from "./env";


type tokenType = {
    access_token: string,
    refresh_token: string
}

const setTokens = (tokens: tokenType) => {
    localStorage.setItem("accessToken", tokens.access_token);
    localStorage.setItem("accessTokenExp", parseJwt(tokens.access_token).exp);
    localStorage.setItem("refreshToken", tokens.refresh_token);
}

export const refreshAuth = async () => {
    const expRaw = localStorage.getItem("accessTokenExp") || "0";
    const exp = parseInt(expRaw)
    if (exp - 3 < Math.floor(new Date().getTime() / 1000)) {
        const resp = await authRefreshApi.refreshJwtTokenAuthJwtRefreshPost()
        setTokens(resp.data)
    }
    return Promise.resolve();
}


type loginFormType = {
  email: string;
  password: string;
};

const parseJwt = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

const projectAuthProvider = {
  login: async ({ email, password }: loginFormType) => {
    const formData = { username: email, password };
    const resp = await authApi.authJwtLoginAuthJwtLoginPost(formData);
    setTokens(resp.data)
    return Promise.resolve();
  },
  logout: () => {
    return Promise.resolve();
  },
  checkError: (error: { status: number }) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      return Promise.resolve();
    }
    return Promise.resolve();
  },
  checkAuth: (a: any) => {
    return localStorage.getItem("accessToken") ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const role = JSON.parse(localStorage.getItem("permissions") || "{}");
    // return role ? Promise.resolve(role) : Promise.reject();
      return Promise.resolve(role)
  },
  getIdentity: async (): Promise<UserIdentity> => {
    const resp = await userApi.usersCurrentUserUsersMeGet();
    if (resp.status === 401) {
      // localStorage.removeItem("accessToken")
    } else {
      localStorage.setItem("permissions", JSON.stringify(resp.data));
    }
    return resp.data as UserIdentity;
  },
};

export default addRefreshAuthToAuthProvider(projectAuthProvider, refreshAuth);
