import React from "react";
import {
    Create,
    Datagrid,
    Edit,
    List,
    SimpleForm,
    TextField,
    TextInput
} from "react-admin";
import {Grid} from "@mui/material";


export const FilterList = (props: any) => (
    <List {...props} filters={[]} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="title"/>
        </Datagrid>
    </List>
);


export const FilterCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <TextInput name="title" source="title" fullWidth required/>
                        <TextInput name="query" source="query" multiline fullWidth
                                   defaultValue="{}" required/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};


export const FilterEdit = (props: any) => {
    return <Edit {...props}>
        <SimpleForm>
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <TextInput name="title" source="title" fullWidth required/>
                    <TextInput name="query" source="query" multiline fullWidth required/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
};
