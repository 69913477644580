import React, {useState} from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {Confirm} from "react-admin";

export const Trash = (props: {handleDelete: any}) => {
        const [open, setOpen] = useState(false);
        const handleClick = () => setOpen(true);
        const handleDialogClose = () => setOpen(false);
        return <>
            <DeleteIcon sx={{position: "absolute", right: "-30px", top: "80px"}}
                           onClick={handleClick} />
            <Confirm
                isOpen={open}
                title="Удалить блок?"
                confirm="Да"
                cancel="Нет"
                content="Вы уверены, что хотите удалить блок?"
                onConfirm={() => props.handleDelete()}
                onClose={handleDialogClose}
            />
        </>
    }
