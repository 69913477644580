import {
    Create,
    Datagrid, DateField,
    Edit,
    List, SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar, useRecordContext
} from "react-admin";
import React from "react";
import {Grid} from '@mui/material';
import {useFormContext} from "react-hook-form";
import MDEditor from "@uiw/react-md-editor";

const SMSBody = () => {
    const record = useRecordContext()
    const [bodyValue, setBodyValue] = React.useState(record && record.body ? record.body : "");
    const {setValue} = useFormContext();
    setValue("body", bodyValue)
    return (
        <div className="container">
            <MDEditor
                value={bodyValue}
                onChange={setBodyValue}
                height={500}
            />
        </div>
    );
}

export const SMSTemplateList = (props: any) => (
    <List {...props} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="title"/>
            <TextField source="name"/>
            <DateField showTime={true} source="created_at"/>
        </Datagrid>
    </List>
);

const SMSTemplateToolBar = () => <Toolbar><SaveButton label="Save" alwaysEnable={true}/></Toolbar>


export const SMSTemplateEdit = (props: any) => {
    return <Edit {...props}>
        <SimpleForm toolbar={<SMSTemplateToolBar/>}>
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <TextInput name="name" source="name" fullWidth/>
                    <TextInput name="title" source="title" fullWidth/>
                    <SMSBody/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
};


export const SMSTemplateCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm toolbar={<SMSTemplateToolBar/>}>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <TextInput name="name" source="name" fullWidth/>
                        <TextInput name="title" source="title" fullWidth/>
                        <SMSBody/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};
