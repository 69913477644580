import {useController, useFormContext} from "react-hook-form";
import React, {useEffect} from "react";
import MDEditor from '@uiw/react-md-editor';
import {useRecordContext} from "react-admin";
import Button from "@mui/material/Button";
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import TextField from "@mui/material/TextField";
import { FormGroup } from '@mui/material';
import Stack from '@mui/material/Stack';
import {Trash} from "./Trash";


const MarkDown = (props: {populateInput: React.Dispatch<React.SetStateAction<any>>, defaultValue: any}) => {
  const [value, setValue] = React.useState();
  if (value == null || value !== props.defaultValue) {
      setValue(props.defaultValue)
  }
  const setNewValue = (val:any):void => {
    props.populateInput(val);
    setValue(val);
  }
  return (
    <div className="container">
      <MDEditor
        value={value}
        preview="edit"
        onChange={setNewValue}
        defaultValue={props.defaultValue}
        height={500}
      />
    </div>
  );
}


const ImageBlock = (props: {populateInput: React.Dispatch<React.SetStateAction<any>>, defaultValue: any}) => {
    const initial = [{url: "", width: 550, height: 550, link: ""}]
    const [value, setValue] = React.useState(props.defaultValue || initial);
    const handleBlockData = (e:any) => {
        const newVal = [{...value[0], [e.target.name]: e.target.value} ]
        setValue(newVal)
        props.populateInput(newVal)
    }
    const handleIntBlockData = (e:any) => {
        const newVal = [{...value[0], [e.target.name]: parseInt(e.target.value)} ]
        setValue(newVal)
        props.populateInput(newVal)
    }
    return <FormGroup>
        <TextField value={value[0].url} label="Image url" onChange={handleBlockData} name="url" type="url" />
        <Stack direction="row"  alignItems="center" justifyContent="space-between">
            <TextField inputProps={{ inputMode: 'numeric' }} value={value[0].width} label="Image width" onChange={handleIntBlockData} name="width" type="number" sx={{width: "47%"}} />
            <TextField inputProps={{ inputMode: 'numeric' }} value={value[0].height} label="Image height" onChange={handleIntBlockData} name="height" type="number" sx={{width: "47%"}} />
        </Stack>
        <TextField value={value[0].link} label="Link or action" onChange={handleBlockData} name="link" type="text" />
    </FormGroup>
}


const ButtonBlock = (props: {populateInput: React.Dispatch<React.SetStateAction<any>>, defaultValue: any}) => {
    const initial = {title: "Button", title_color: "#000000", bg_color: "#fecb3e", link: "https://deeplink"}
    const [value, setValue] = React.useState(props.defaultValue || initial);
    const handleBlockData = (e:any) => {
        const newVal = {...value, [e.target.name]: e.target.value}
        setValue(newVal)
        props.populateInput(newVal)
    }
    return <FormGroup>
        <TextField value={value.title} label="Title" onChange={handleBlockData} name="title" type="text" />
        <Stack direction="row"  alignItems="center" justifyContent="space-between">
            <TextField value={value.title_color} label="Title color" onChange={handleBlockData} name="title_color" type="color" sx={{width: "47%"}}/>
            <TextField value={value.bg_color} label="Background color" onChange={handleBlockData} name="bg_color" type="color" sx={{width: "47%"}}/>
        </Stack>
        <TextField value={value.link} label="Link or action" onChange={handleBlockData} name="link" type="url" />
    </FormGroup>
}


export const BodyField = (props: {setPreviewElements: React.Dispatch<React.SetStateAction<any>>}) => {
    const record = useRecordContext()
    const bodyStore = record && record.body ? record.body : [
        {"element": "image", "data": [{"height": 550, "width": 550, "url": "", "link": ""}]},
        {"element": "markdown", "data": {"body": ""}},
        {"element": "button", "data": {
            title: "Button", title_color: "#000000", bg_color: "#fecb3e", link: "https://deeplink"
        }},
    ]
    const [elements, setElements] = React.useState(bodyStore);
    const body = useController({ name: 'body', defaultValue: '' });
    const {setValue} = useFormContext();
    useEffect(() => props.setPreviewElements(elements))
    const setNewValue = (val:any, key:number):void => {
        const newElements = elements.map((element:any, index:number) => {
            if (element.element === "markdown") {
                return index === key ? {...element, data: {...element.data, body: val}} : element
            } else {
                return index === key ? {...element, data: val} : element
            }
        })
        setElements(newElements)
        setValue("body", newElements)
        props.setPreviewElements(newElements)
    }
    const addNewElement = (elementType:string):void => {
        if (elementType === "markdown") {
            setElements([...elements, {element: "markdown", data: {body: ""}}])
        } else if (elementType === "image") {
            setElements([...elements, {element: "image", data: [{url: "", width: 550, height: 550, link: ""}]}])
        } else if (elementType === "button") {
            setElements([...elements, {element: "button", data: {title: "Button", title_color: "#000000", bg_color: "#fecb3e", link: "https://deeplink"}}])
        }
    }

    const deleteElement = (index: number) => {
        const newElements = elements.filter((element: any, idx: number) => index !== idx)
        setElements(newElements)
        setValue("body", newElements)
    }
    const moveElementUp = (index: number) => {
        [elements[index-1], elements[index]] = [elements[index], elements[index-1]]
        const newElements = [...elements]
        setValue("body", newElements)
        setElements(newElements)
    }
    const moveElementDown = (index: number) => {
        [elements[index+1], elements[index]] = [elements[index], elements[index+1]]
        const newElements = [...elements]
        setValue("body", newElements)
        setElements(newElements)
    }

    const MoveUp = (props: {index: number}) => {
        return <ArrowUpward sx={{position: "absolute", right: "-30px", top: "10px"}}
                           onClick={() => moveElementUp(props.index)} />
    }
    const MoveDown = (props: {index: number}) => {
        return <ArrowDownward sx={{position: "absolute", right: "-30px", top: "30px"}}
                           onClick={() => moveElementDown(props.index)} />
    }

    const ManipulateBlock = (props: {index: number, lastIndex: number}) => {
        return <>
            <Trash handleDelete={() => deleteElement(props.index)}/>
            {props.index === 0 ? null : <MoveUp index={props.index} />}
            {props.lastIndex === props.index + 1 ? null : <MoveDown index={props.index} />}
        </>
    }

    return <>
        <input {...body.field} id="body" type="hidden" />
        <Stack spacing={2}>
        {elements.map(
            (element:any, index:number) => {
                if (element.element === "markdown") {
                    return <div style={{position: "relative"}} key={index}>
                        <ManipulateBlock index={index} lastIndex={elements.length} />
                        <MarkDown
                            defaultValue={element.data.body}
                            populateInput={(val:string) => setNewValue(val, index)}
                        />
                    </div>
                } else if (element.element === "image") {
                    return <div style={{position: "relative"}} key={index}>
                        <ManipulateBlock index={index} lastIndex={elements.length} />
                        <ImageBlock
                            defaultValue={element.data}
                            populateInput={(val:any) => setNewValue(val, index)}
                        />
                    </div>
                } else if (element.element === "button") {
                    return <div style={{position: "relative"}} key={index}>
                        <ManipulateBlock index={index} lastIndex={elements.length} />
                        <ButtonBlock
                            defaultValue={element.data}
                            populateInput={(val:string) => setNewValue(val, index)}
                        />
                    </div>
                }
                return null
            }
        )}
        <ButtonGroup variant="contained" aria-label="outlined primary button group" sx={{alignSelf: "center"}}>
          <Button onClick={() => addNewElement("image")}>+ Image</Button>
          <Button onClick={() => addNewElement("markdown")}>+ Text</Button>
          <Button onClick={() => addNewElement("button")}>+ Button</Button>
        </ButtonGroup>
        </Stack>
    </>
}
