import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MarkdownView from "react-showdown";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import React from "react";

const dummyImage: string = "https://ystyangin.com/wp-content/uploads/dummy-image-square.jpg"

const BodyPreview = (props: {
    elements: any
}) => {
    return <>
        <Card>
            {props.elements.map((element:any, index:number) => {
                if (element.element === "markdown") {
                    return <CardContent key={index}><MarkdownView key={index} markdown={element.data.body} options={{
                        tables: true,
                        emoji: true,
                        strikethrough: true
                    }}/></CardContent>
                } else if (element.element === "image") {
                    return <CardMedia sx={{height: element.data[0].height}} image={element.data[0].url || dummyImage} title=""
                                      key={index}/>
                } else if (element.element === "button") {
                    return <CardActions key={index}>
                        <Button style={{backgroundColor: element.data.bg_color, color: element.data.title_color}}
                                fullWidth variant="contained">{element.data.title}</Button></CardActions>
                } else return null
            })}
        </Card>
    </>
}

export default BodyPreview
