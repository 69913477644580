import {
    BooleanInput,
    Create,
    Datagrid, DateField,
    DateTimeInput,
    Edit, FunctionField,
    List, NullableBooleanInput, required, SaveButton, SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar, useGetList, useRecordContext
} from "react-admin";
import {FormControlLabel, Radio, RadioGroup} from "@mui/material";
import React, {useState} from "react";
import { Grid } from '@mui/material';
import Box from "@mui/material/Box";
import {StoryList, StoryPreview} from "../components/StoryPreview";
import Stack from "@mui/material/Stack";
import {useFormContext} from "react-hook-form";



const TemplatesField = () => {
    const {data, isLoading} = useGetList(
        'templates',
        {pagination: {page: 1, perPage: 100}}
    );
    return (
        <SelectInput
            name="template"
            source="template"
            choices={data}
            optionText="title"
            optionValue="id"
            isLoading={isLoading}
            fullWidth={true}
            validate={required()}
        />
    );
}


const postFilters = [
    <NullableBooleanInput label="Status filter" name="enabled" source="enabled" defaultValue={""}
    trueLabel={"Enabled"} falseLabel={"Disabled"} nullLabel={"All"}/> ,
]

export const StoriesList = (props: any) => (
  <List {...props} filters={postFilters} perPage={25}>
    <StoryList />
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="type" />
      <TextField source="read_count" />
      <TextField source="read_count_unique" />
      <FunctionField
          label="Status"
          render={(record: {enabled: boolean}) => record.enabled ? "✅ Enabled" : "Disabled"}
      />
      <DateField source="datetime" showTime={true} label={"Datetime start (UTC)"}/>
    </Datagrid>
  </List>
);

const StoryToolBar = () => <Toolbar><SaveButton label="Save" alwaysEnable={true} /></Toolbar>
const typeChoicesList = [
    { id: "commercial", name: "Commercial" },
    { id: "internal", name: "Internal" },
    { id: "user_invite", name: "User Invite" },
]


const StoryColorPicker = ({source}: {source: string}) => {
    const { setValue } = useFormContext();
    const record = useRecordContext();
    const [color, setColor] = useState(record ? record[source] : "#ffffff");
    const handleRadioChange = (event: any) => {
        const eventValue = event.target.value;
        const radioVal = ["#ffffff", "#000000"].includes(eventValue) ? eventValue : ""
        setColor(radioVal)
        setValue("color", eventValue);
    }
    return <Stack direction="row" alignItems="center" justifyContent="space-between">
        <TextInput defaultValue={"#ffffff"}
                   name={"title_color"}
                   onChange={handleRadioChange}
                   source={source}
                   type={"color"}
                   label={"Title color"}
                   sx={{width: "50%"}} />
        <RadioGroup
            onChange={handleRadioChange}
            defaultValue={"#ffffff"}
            value={color}
            defaultChecked={false}
            name="radio-button-color"
            sx={{width: "40%"}}
            row
        >
            <FormControlLabel value="#ffffff" control={<Radio onChange={handleRadioChange}/>} label="White" sx={{marginRight: "10px"}}/>
            <FormControlLabel value="#000000" control={<Radio onChange={handleRadioChange}/>} label="Black" sx={{marginRight: 0}}/>
        </RadioGroup>
    </Stack>
}


const ForNewUsersField = () => {
    const formContext = useFormContext();
    const requiredInputs = formContext.getValues('for_new_users')
    const validators = !!requiredInputs ? required() : undefined
    return <>
        <BooleanInput label="Для новых пользователей" source="for_new_users" name="for_new_users"/>
        <TextInput label="Изображение в списке для новых пользователей" source="body_image" name="body_image" fullWidth
                   validate={validators}/>
        <TextInput label="Описание под картинкой для новых пользователей" source="body_description"
                   name="body_description" fullWidth validate={validators}/>
    </>
}


export const StoriesEdit = (props: any) => {
    return <Edit {...props}>
        <SimpleForm toolbar={<StoryToolBar/>}>
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <TextInput name="title" source="title" fullWidth/>
                    <StoryColorPicker source={"color"}/>
                    <TextInput name="read_count" source="read_count" disabled/>
                    <TextInput name="read_count_unique" source="read_count_unique" disabled/>
                    <BooleanInput title="Включен" name="enabled" source="enabled" />
                    <DateTimeInput name="datetime" source="datetime"/>
                    <TextInput name="background_image" source="background_image" fullWidth />
                    <TextInput name="link" source="link" multiline fullWidth />
                    <SelectInput name="type" source="type" defaultValue={typeChoicesList[0].id} fullWidth choices={typeChoicesList} validate={required()} />
                    <ForNewUsersField />
                    <TemplatesField/>
                </Grid>
                <Grid item xs={6}>
                    <Box textAlign={"center"}>Story List Preview</Box>
                    <StoryPreview />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
};


export const StoriesCreate = (props: any) => {
    const record = useRecordContext();
    console.log(record)
    return (
        <Create {...props}>
            <SimpleForm toolbar={<StoryToolBar />}>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <TextInput name="title" source="title" fullWidth />
                        <StoryColorPicker source={"color"}/>
                        <BooleanInput title="Включен" name="enabled" source="enabled" />
                        <DateTimeInput name="datetime" source="datetime" />
                        <TextInput name="background_image" source="background_image" fullWidth />
                        <TextInput name="link" source="link" multiline fullWidth />
                        <SelectInput name="type" source="type" defaultValue={typeChoicesList[0].id} fullWidth choices={typeChoicesList} validate={required()} />
                        <BooleanInput label="Для новых пользователей" source="for_new_users" name="for_new_users" />
                        <ForNewUsersField />
                        <TemplatesField/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};
