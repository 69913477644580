import {
    Create,
    Datagrid, DateField,
    Edit,
    List, SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar
} from "react-admin";
import React from "react";
import { Grid } from '@mui/material';
import {BodyField} from "../components/BodyField";
import Box from "@mui/material/Box";
import BodyPreview from '../components/BodyPreview'

export const TemplateList = (props: any) => (
  <List {...props} perPage={25}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="short_body" />
      <DateField showTime={true} source="created_at"/>
    </Datagrid>
  </List>
);

const TemplateToolBar = () => <Toolbar><SaveButton label="Save" alwaysEnable={true} /></Toolbar>


export const TemplateEdit = (props: any) => {
    const [elements, setPreviewElements] = React.useState([]);
    return <Edit {...props}>
        <SimpleForm toolbar={<TemplateToolBar/>}>
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <TextInput name="name" source="name" fullWidth/>
                    <TextInput name="title" source="title" fullWidth/>
                    <TextInput name="short_body" source="short_body" multiline fullWidth/>
                    <BodyField setPreviewElements={setPreviewElements}/>
                </Grid>
                <Grid item xs={6}>
                    <Box textAlign={"center"}>Message Preview</Box>
                    <BodyPreview elements={elements} />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
};


export const TemplateCreate = (props: any) => {
    const [elements, setPreviewElements] = React.useState([]);
    return (
        <Create {...props}>
            <SimpleForm toolbar={<TemplateToolBar />}>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <TextInput name="name" source="name" fullWidth/>
                        <TextInput name="title" source="title" fullWidth />
                        <TextInput name="short_body" source="short_body" multiline fullWidth />
                        <BodyField setPreviewElements={setPreviewElements} />
                    </Grid>
                    <Grid item xs={6}>
                        <Box textAlign={"center"}>Message Preview</Box>
                        <BodyPreview elements={elements} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};
