import { Admin, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router";
import MyLayout from "./components/AdminLayout";
import { TemplateCreate, TemplateEdit, TemplateList } from "./pages/templates";
import { CampaignCreate, CampaignEdit, CampaignList } from "./pages/campaigns";
import LoginPage from "./pages/Login";
import { ProfileEdit } from "./pages/ProfileEdit";
import Register from "./pages/Register";
import { UserEdit, UserList } from "./pages/Users";
import authProvider from "./providers/authProvider";
import PostIcon from "@mui/icons-material/PostAdd";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import MailSharpIcon from '@mui/icons-material/MailSharp';
import PersonIcon from "@mui/icons-material/Person";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import dataProvider from "./providers/dataProvider";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import SmsIcon from '@mui/icons-material/Sms';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {FilterCreate, FilterEdit, FilterList} from "./pages/filters";
import {StoriesCreate, StoriesEdit, StoriesList} from "./pages/stories";
import {CustomerEdit, CustomerList} from "./pages/customers";
import {SMSCampaignCreate, SMSCampaignEdit, SMSCampaignList} from "./pages/SMSCampaign";
import {SMSTemplateCreate, SMSTemplateEdit, SMSTemplateList} from "./pages/SMSTemplates";
import {PushNotificationList} from "./pages/push_notifications";


const App = () => {
  return (
    <Admin
      disableTelemetry
      dataProvider={dataProvider}
      authProvider={authProvider}
      loginPage={LoginPage}
      layout={MyLayout}
    >
      <CustomRoutes>
        <Route path="/profile" element={<ProfileEdit />} />
      </CustomRoutes>
      <CustomRoutes noLayout>
        <Route path="/register" element={<Register />} />
      </CustomRoutes>
      {(permissions) => [
        permissions.is_superuser === true ? (
          <Resource
            options={{ label: "Users" }}
            name="users"
            list={UserList}
            edit={UserEdit}
            icon={PersonIcon}
          />
        ) : null,
        <Resource
          name="templates"
          options={{ label: "Шаблоны" }}
          list={TemplateList}
          edit={TemplateEdit}
          create={TemplateCreate}
          icon={PostIcon}
        />,
        <Resource
          name="stories"
          options={{ label: "Сторис" }}
          list={StoriesList}
          edit={StoriesEdit}
          create={StoriesCreate}
          icon={NewspaperIcon}
        />,
        <Resource
          name="campaigns"
          options={{ label: "Кампании" }}
          list={CampaignList}
          edit={CampaignEdit}
          create={CampaignCreate}
          icon={RocketLaunchIcon}
        />,
        <Resource
          name="filters"
          options={{ label: "Фильтры" }}
          list={FilterList}
          edit={FilterEdit}
          create={FilterCreate}
          icon={FilterFramesIcon}
        />,
        <Resource
          name="customers"
          options={{ label: "Пользователи" }}
          list={CustomerList}
          edit={CustomerEdit}
          icon={PersonPinIcon}
        >
          <Route path=":id/push_notifications" element={<PushNotificationList />} />
        </Resource>,
        <Resource
          name="sms"
          options={{ label: "SMS рассылки" }}
          list={SMSCampaignList}
          edit={SMSCampaignEdit}
          create={SMSCampaignCreate}
          icon={SmsIcon}
        />,
        <Resource
          name="sms_templates"
          options={{ label: "SMS шаблоны" }}
          list={SMSTemplateList}
          edit={SMSTemplateEdit}
          create={SMSTemplateCreate}
          icon={TextSnippetIcon}
        />,
        <Resource
          name="push_notifications"
          options={{ label: "Пуш нотификации" }}
          list={PushNotificationList}
          icon={MailSharpIcon}
        />
      ]}
    </Admin>
  );
};

export default App;
