import {useWatch} from "react-hook-form";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import React, {useState} from "react";
import {useListContext} from "react-admin";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import KeyboardArrowDownIcon from
    "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from
    "@mui/icons-material/KeyboardArrowUp";
import IconButton from "@mui/material/IconButton";


const styles = {
    wrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    media: {
        paddingTop: 0,
    },
    card: {
        position: 'relative' as 'relative',
        width: '240px',
    },
    overlay: {
        position: 'absolute' as 'absolute',
        top: '20px',
        left: '20px',
        color: 'white',
        font: 'Inter',
        fontSize: '26px',
        fontWeight: 'bold',
        lineHeight: '30px',
    }
}


const StoryListWidget = (props: {story: any, ratio: number}) => {
    const ratio: number = !props.ratio ? 1 : props.ratio
    const cardStyle = {...styles.card, width: 120 * ratio + 'px'}
    const overlayStyle = {...styles.overlay,
        fontSize: 13 * ratio + 'px',
        lineHeight: 15 * ratio + 'px',
        top: 10 * ratio + 'px',
        left: 10 * ratio + 'px',
        color: props.story.color
    } as const
    return <Card style={cardStyle}>
        <CardMedia style={styles.media}
                   component="img"
                   height={128 * ratio}
                   image={props.story.background_image}
                   alt={props.story.title}
        />
        <div style={overlayStyle}>{props.story.title}</div>
    </Card>
}


export const StoryPreview = () => {
    const story = useWatch()
    return <div style={styles.wrapper}>
        {!story.background_image ? null :
            <StoryListWidget story={story} ratio={2} />
        }
        </div>
}

export const StoryList = () => {
    const {data, isLoading} = useListContext()
    const [open, setOpen] = useState(false);
    return <Card sx={{
                minWidth: 300,
                border: "1px solid rgba(211,211,211,0.6)"
            }}>
        <CardHeader
            title="Stories list preview"
            action={
                <IconButton
                    onClick={() => setOpen(!open)}
                    aria-label="expand"
                    size="small"
                >
                    {open ? <KeyboardArrowUpIcon/>
                        : <KeyboardArrowDownIcon/>}
                </IconButton>
            }
        ></CardHeader>
    <Collapse in={open}> <div style={{display: 'grid', overflowX: 'scroll', width: '380px', marginLeft: 'auto', marginRight: 'auto'}}>
        <div style={{display: 'flex', columnGap: '6px', overflowX: 'scroll', justifySelf: 'baseline'}}>
        {isLoading ? null : data.map((story, key) => <StoryListWidget key={key} story={story} ratio={1} />)}
        </div>
    </div></Collapse>
    </Card>
}
