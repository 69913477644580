import { basePath } from "./env";
import simpleRestProvider from "ra-data-simple-rest";
import {addRefreshAuthToDataProvider, fetchUtils} from "react-admin";
import { withLifecycleCallbacks } from 'react-admin';
import {refreshAuth} from "./authProvider";
const httpClient = (url: string, options: any = {}) => {
  options.user = {
    authenticated: true,
    token: `Bearer ${localStorage.getItem("accessToken")}`,
  };
  if (url.includes("/users/") && options.method === "PUT") {
    // We use PATCH for update on the backend for users, since PATCH is selective PUT, this change should be fine
    options.method = "PATCH";
  }
  return fetchUtils.fetchJson(url, options);
};

const campaignsSaveSubscribersFileToText = async (params: any, dataProvider: any) => {
        // Freshly dropped subscribers are File objects and must be converted to text strings
        if (params.data.subscribers === undefined)
        {
            return { data: { ...params.data } }
        }
        const newSubscribers = params.data.subscribers.filter(
            (p: any) => p.rawFile instanceof File
        );
        const formerSubscribers = params.data.subscribers.filter(
            (p: any) => !(p.rawFile instanceof File)
        );

        const textSubscribers = await Promise.all(
            newSubscribers.map(convertFileToText)
        )
        const subscribers = [
            ...textSubscribers.map((dataUrl, index) => ({
                src: dataUrl,
                title: newSubscribers[index].name,
            })),
            ...formerSubscribers,
        ];
        return { data: { ...params.data, subscribers } }
    }


const dataProvider = withLifecycleCallbacks(simpleRestProvider(`${basePath}`, httpClient), [
    {
        /**
         * For posts update only, convert uploaded images to base 64 and attach them to
         * the `subscriber` sent property, with `src` and `title` attributes.
         */
        resource: 'campaigns',
        beforeCreate: campaignsSaveSubscribersFileToText
    }
]);

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToText = (file: any) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsText(file.rawFile);
    });

export default dataProvider;
