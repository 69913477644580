import {
    Datagrid, DateField, DateTimeInput,
    Edit, List, SimpleForm,
    TextField, TextInput, DateInput, FunctionField, NumberInput, useRecordContext
} from "react-admin";
import React from "react";
import {Grid} from '@mui/material';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import MailSharpIcon from "@mui/icons-material/MailSharp";


const PushNotificationListButton = () => {
    const customer = useRecordContext();
    return (
        <Button
            component={Link}
            to={`/push_notifications?filter=%7B"customer_id"%3A"${customer?.customer_id}"%7D`}
            startIcon={<MailSharpIcon />}
        >
            Push Notifications
        </Button>
    );
};


export const CustomerEdit = (props: any) => {
    return <Edit {...props}>
        <SimpleForm>
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <TextInput name="customer_id" source="customer_id" disabled/>
                    <TextInput name="last_name" source="last_name" fullWidth disabled/>
                    <TextInput name="first_name" source="first_name" fullWidth disabled/>
                    <TextInput name="middle_name" source="middle_name" fullWidth disabled/>
                    <TextInput name="phone" source="phone" fullWidth/>
                    <DateInput name="birth_date" source="birth_date" fullWidth disabled/>
                    <TextInput name="gender_id" source="gender_id" disabled/>
                    <TextInput name="contract_id" source="contract_id" fullWidth disabled/>
                    <DateTimeInput name="last_operation_datetime" source="last_operation_datetime" fullWidth disabled/>
                    <PushNotificationListButton/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
};


const postFilters = [
    <NumberInput name="customer_id" label="Search by customer_id" source="customer_id" alwaysOn/>,
];

export const CustomerList = (props: any) => (
    <List {...props} filters={postFilters} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="customer_id"/>
            <TextField source="last_name"/>
            <TextField source="first_name"/>
            <TextField source="middle_name"/>
            <TextField source="phone"/>
            <DateField source="birth_date"/>
            <FunctionField
                label="Gender"
                render={(record: { gender_id: any; }) => `${record.gender_id}` === "1" ? "Муж" :
                    `${record.gender_id}` === "2" ? "Жен" : ""}
            />
            <TextField source="contract_id"/>
            <DateField source="last_operation_datetime" showTime={true}/>
        </Datagrid>
    </List>
);
