/* tslint:disable */
/* eslint-disable */
/**
 * Communication API
 * Communication Platform API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BearerResponseRefresh } from '../models';
// @ts-ignore
import { BodyResetForgotPasswordAuthForgotPasswordPost } from '../models';
// @ts-ignore
import { BodyResetResetPasswordAuthResetPasswordPost } from '../models';
// @ts-ignore
import { BodyVerifyRequestTokenAuthRequestVerifyTokenPost } from '../models';
// @ts-ignore
import { BodyVerifyVerifyAuthVerifyPost } from '../models';
// @ts-ignore
import { ErrorModel } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { UserCreate } from '../models';
// @ts-ignore
import { UserRead } from '../models';
/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Auth:Jwt.Login
         * @param {any} username 
         * @param {any} password 
         * @param {any | null} [grantType] 
         * @param {any} [scope] 
         * @param {any | null} [clientId] 
         * @param {any | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authJwtLoginAuthJwtLoginPost: async (username: any, password: any, grantType?: any | null, scope?: any, clientId?: any | null, clientSecret?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('authJwtLoginAuthJwtLoginPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('authJwtLoginAuthJwtLoginPost', 'password', password)
            const localVarPath = `/auth/jwt/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Auth:Jwt.Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authJwtLogoutAuthJwtLogoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/jwt/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh Jwt Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJwtTokenAuthJwtRefreshPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/jwt/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2PasswordBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2PasswordBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Register:Register
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerRegisterAuthRegisterPost: async (userCreate: UserCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userCreate' is not null or undefined
            assertParamExists('registerRegisterAuthRegisterPost', 'userCreate', userCreate)
            const localVarPath = `/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset:Forgot Password
         * @param {BodyResetForgotPasswordAuthForgotPasswordPost} bodyResetForgotPasswordAuthForgotPasswordPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetForgotPasswordAuthForgotPasswordPost: async (bodyResetForgotPasswordAuthForgotPasswordPost: BodyResetForgotPasswordAuthForgotPasswordPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyResetForgotPasswordAuthForgotPasswordPost' is not null or undefined
            assertParamExists('resetForgotPasswordAuthForgotPasswordPost', 'bodyResetForgotPasswordAuthForgotPasswordPost', bodyResetForgotPasswordAuthForgotPasswordPost)
            const localVarPath = `/auth/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyResetForgotPasswordAuthForgotPasswordPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset:Reset Password
         * @param {BodyResetResetPasswordAuthResetPasswordPost} bodyResetResetPasswordAuthResetPasswordPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetResetPasswordAuthResetPasswordPost: async (bodyResetResetPasswordAuthResetPasswordPost: BodyResetResetPasswordAuthResetPasswordPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyResetResetPasswordAuthResetPasswordPost' is not null or undefined
            assertParamExists('resetResetPasswordAuthResetPasswordPost', 'bodyResetResetPasswordAuthResetPasswordPost', bodyResetResetPasswordAuthResetPasswordPost)
            const localVarPath = `/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyResetResetPasswordAuthResetPasswordPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify:Request-Token
         * @param {BodyVerifyRequestTokenAuthRequestVerifyTokenPost} bodyVerifyRequestTokenAuthRequestVerifyTokenPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyRequestTokenAuthRequestVerifyTokenPost: async (bodyVerifyRequestTokenAuthRequestVerifyTokenPost: BodyVerifyRequestTokenAuthRequestVerifyTokenPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyVerifyRequestTokenAuthRequestVerifyTokenPost' is not null or undefined
            assertParamExists('verifyRequestTokenAuthRequestVerifyTokenPost', 'bodyVerifyRequestTokenAuthRequestVerifyTokenPost', bodyVerifyRequestTokenAuthRequestVerifyTokenPost)
            const localVarPath = `/auth/request-verify-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyVerifyRequestTokenAuthRequestVerifyTokenPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify:Verify
         * @param {BodyVerifyVerifyAuthVerifyPost} bodyVerifyVerifyAuthVerifyPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyVerifyAuthVerifyPost: async (bodyVerifyVerifyAuthVerifyPost: BodyVerifyVerifyAuthVerifyPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyVerifyVerifyAuthVerifyPost' is not null or undefined
            assertParamExists('verifyVerifyAuthVerifyPost', 'bodyVerifyVerifyAuthVerifyPost', bodyVerifyVerifyAuthVerifyPost)
            const localVarPath = `/auth/verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyVerifyVerifyAuthVerifyPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Auth:Jwt.Login
         * @param {any} username 
         * @param {any} password 
         * @param {any | null} [grantType] 
         * @param {any} [scope] 
         * @param {any | null} [clientId] 
         * @param {any | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authJwtLoginAuthJwtLoginPost(username: any, password: any, grantType?: any | null, scope?: any, clientId?: any | null, clientSecret?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BearerResponseRefresh>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authJwtLoginAuthJwtLoginPost(username, password, grantType, scope, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Auth:Jwt.Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authJwtLogoutAuthJwtLogoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authJwtLogoutAuthJwtLogoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refresh Jwt Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshJwtTokenAuthJwtRefreshPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshJwtTokenAuthJwtRefreshPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Register:Register
         * @param {UserCreate} userCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerRegisterAuthRegisterPost(userCreate: UserCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerRegisterAuthRegisterPost(userCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset:Forgot Password
         * @param {BodyResetForgotPasswordAuthForgotPasswordPost} bodyResetForgotPasswordAuthForgotPasswordPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetForgotPasswordAuthForgotPasswordPost(bodyResetForgotPasswordAuthForgotPasswordPost: BodyResetForgotPasswordAuthForgotPasswordPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetForgotPasswordAuthForgotPasswordPost(bodyResetForgotPasswordAuthForgotPasswordPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset:Reset Password
         * @param {BodyResetResetPasswordAuthResetPasswordPost} bodyResetResetPasswordAuthResetPasswordPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetResetPasswordAuthResetPasswordPost(bodyResetResetPasswordAuthResetPasswordPost: BodyResetResetPasswordAuthResetPasswordPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetResetPasswordAuthResetPasswordPost(bodyResetResetPasswordAuthResetPasswordPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify:Request-Token
         * @param {BodyVerifyRequestTokenAuthRequestVerifyTokenPost} bodyVerifyRequestTokenAuthRequestVerifyTokenPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyRequestTokenAuthRequestVerifyTokenPost(bodyVerifyRequestTokenAuthRequestVerifyTokenPost: BodyVerifyRequestTokenAuthRequestVerifyTokenPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyRequestTokenAuthRequestVerifyTokenPost(bodyVerifyRequestTokenAuthRequestVerifyTokenPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify:Verify
         * @param {BodyVerifyVerifyAuthVerifyPost} bodyVerifyVerifyAuthVerifyPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyVerifyAuthVerifyPost(bodyVerifyVerifyAuthVerifyPost: BodyVerifyVerifyAuthVerifyPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRead>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyVerifyAuthVerifyPost(bodyVerifyVerifyAuthVerifyPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Auth:Jwt.Login
         * @param {AuthApiAuthJwtLoginAuthJwtLoginPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authJwtLoginAuthJwtLoginPost(requestParameters: AuthApiAuthJwtLoginAuthJwtLoginPostRequest, options?: AxiosRequestConfig): AxiosPromise<BearerResponseRefresh> {
            return localVarFp.authJwtLoginAuthJwtLoginPost(requestParameters.username, requestParameters.password, requestParameters.grantType, requestParameters.scope, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Auth:Jwt.Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authJwtLogoutAuthJwtLogoutPost(options?: AxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.authJwtLogoutAuthJwtLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh Jwt Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJwtTokenAuthJwtRefreshPost(options?: AxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.refreshJwtTokenAuthJwtRefreshPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Register:Register
         * @param {AuthApiRegisterRegisterAuthRegisterPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerRegisterAuthRegisterPost(requestParameters: AuthApiRegisterRegisterAuthRegisterPostRequest, options?: AxiosRequestConfig): AxiosPromise<UserRead> {
            return localVarFp.registerRegisterAuthRegisterPost(requestParameters.userCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset:Forgot Password
         * @param {AuthApiResetForgotPasswordAuthForgotPasswordPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetForgotPasswordAuthForgotPasswordPost(requestParameters: AuthApiResetForgotPasswordAuthForgotPasswordPostRequest, options?: AxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.resetForgotPasswordAuthForgotPasswordPost(requestParameters.bodyResetForgotPasswordAuthForgotPasswordPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset:Reset Password
         * @param {AuthApiResetResetPasswordAuthResetPasswordPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetResetPasswordAuthResetPasswordPost(requestParameters: AuthApiResetResetPasswordAuthResetPasswordPostRequest, options?: AxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.resetResetPasswordAuthResetPasswordPost(requestParameters.bodyResetResetPasswordAuthResetPasswordPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify:Request-Token
         * @param {AuthApiVerifyRequestTokenAuthRequestVerifyTokenPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyRequestTokenAuthRequestVerifyTokenPost(requestParameters: AuthApiVerifyRequestTokenAuthRequestVerifyTokenPostRequest, options?: AxiosRequestConfig): AxiosPromise<any> {
            return localVarFp.verifyRequestTokenAuthRequestVerifyTokenPost(requestParameters.bodyVerifyRequestTokenAuthRequestVerifyTokenPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify:Verify
         * @param {AuthApiVerifyVerifyAuthVerifyPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyVerifyAuthVerifyPost(requestParameters: AuthApiVerifyVerifyAuthVerifyPostRequest, options?: AxiosRequestConfig): AxiosPromise<UserRead> {
            return localVarFp.verifyVerifyAuthVerifyPost(requestParameters.bodyVerifyVerifyAuthVerifyPost, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authJwtLoginAuthJwtLoginPost operation in AuthApi.
 * @export
 * @interface AuthApiAuthJwtLoginAuthJwtLoginPostRequest
 */
export interface AuthApiAuthJwtLoginAuthJwtLoginPostRequest {
    /**
     * 
     * @type {any}
     * @memberof AuthApiAuthJwtLoginAuthJwtLoginPost
     */
    readonly username: any

    /**
     * 
     * @type {any}
     * @memberof AuthApiAuthJwtLoginAuthJwtLoginPost
     */
    readonly password: any

    /**
     * 
     * @type {any}
     * @memberof AuthApiAuthJwtLoginAuthJwtLoginPost
     */
    readonly grantType?: any | null

    /**
     * 
     * @type {any}
     * @memberof AuthApiAuthJwtLoginAuthJwtLoginPost
     */
    readonly scope?: any

    /**
     * 
     * @type {any}
     * @memberof AuthApiAuthJwtLoginAuthJwtLoginPost
     */
    readonly clientId?: any | null

    /**
     * 
     * @type {any}
     * @memberof AuthApiAuthJwtLoginAuthJwtLoginPost
     */
    readonly clientSecret?: any | null
}

/**
 * Request parameters for registerRegisterAuthRegisterPost operation in AuthApi.
 * @export
 * @interface AuthApiRegisterRegisterAuthRegisterPostRequest
 */
export interface AuthApiRegisterRegisterAuthRegisterPostRequest {
    /**
     * 
     * @type {UserCreate}
     * @memberof AuthApiRegisterRegisterAuthRegisterPost
     */
    readonly userCreate: UserCreate
}

/**
 * Request parameters for resetForgotPasswordAuthForgotPasswordPost operation in AuthApi.
 * @export
 * @interface AuthApiResetForgotPasswordAuthForgotPasswordPostRequest
 */
export interface AuthApiResetForgotPasswordAuthForgotPasswordPostRequest {
    /**
     * 
     * @type {BodyResetForgotPasswordAuthForgotPasswordPost}
     * @memberof AuthApiResetForgotPasswordAuthForgotPasswordPost
     */
    readonly bodyResetForgotPasswordAuthForgotPasswordPost: BodyResetForgotPasswordAuthForgotPasswordPost
}

/**
 * Request parameters for resetResetPasswordAuthResetPasswordPost operation in AuthApi.
 * @export
 * @interface AuthApiResetResetPasswordAuthResetPasswordPostRequest
 */
export interface AuthApiResetResetPasswordAuthResetPasswordPostRequest {
    /**
     * 
     * @type {BodyResetResetPasswordAuthResetPasswordPost}
     * @memberof AuthApiResetResetPasswordAuthResetPasswordPost
     */
    readonly bodyResetResetPasswordAuthResetPasswordPost: BodyResetResetPasswordAuthResetPasswordPost
}

/**
 * Request parameters for verifyRequestTokenAuthRequestVerifyTokenPost operation in AuthApi.
 * @export
 * @interface AuthApiVerifyRequestTokenAuthRequestVerifyTokenPostRequest
 */
export interface AuthApiVerifyRequestTokenAuthRequestVerifyTokenPostRequest {
    /**
     * 
     * @type {BodyVerifyRequestTokenAuthRequestVerifyTokenPost}
     * @memberof AuthApiVerifyRequestTokenAuthRequestVerifyTokenPost
     */
    readonly bodyVerifyRequestTokenAuthRequestVerifyTokenPost: BodyVerifyRequestTokenAuthRequestVerifyTokenPost
}

/**
 * Request parameters for verifyVerifyAuthVerifyPost operation in AuthApi.
 * @export
 * @interface AuthApiVerifyVerifyAuthVerifyPostRequest
 */
export interface AuthApiVerifyVerifyAuthVerifyPostRequest {
    /**
     * 
     * @type {BodyVerifyVerifyAuthVerifyPost}
     * @memberof AuthApiVerifyVerifyAuthVerifyPost
     */
    readonly bodyVerifyVerifyAuthVerifyPost: BodyVerifyVerifyAuthVerifyPost
}

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Auth:Jwt.Login
     * @param {AuthApiAuthJwtLoginAuthJwtLoginPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authJwtLoginAuthJwtLoginPost(requestParameters: AuthApiAuthJwtLoginAuthJwtLoginPostRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authJwtLoginAuthJwtLoginPost(requestParameters.username, requestParameters.password, requestParameters.grantType, requestParameters.scope, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Auth:Jwt.Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authJwtLogoutAuthJwtLogoutPost(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authJwtLogoutAuthJwtLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh Jwt Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshJwtTokenAuthJwtRefreshPost(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshJwtTokenAuthJwtRefreshPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Register:Register
     * @param {AuthApiRegisterRegisterAuthRegisterPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public registerRegisterAuthRegisterPost(requestParameters: AuthApiRegisterRegisterAuthRegisterPostRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).registerRegisterAuthRegisterPost(requestParameters.userCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset:Forgot Password
     * @param {AuthApiResetForgotPasswordAuthForgotPasswordPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetForgotPasswordAuthForgotPasswordPost(requestParameters: AuthApiResetForgotPasswordAuthForgotPasswordPostRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).resetForgotPasswordAuthForgotPasswordPost(requestParameters.bodyResetForgotPasswordAuthForgotPasswordPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset:Reset Password
     * @param {AuthApiResetResetPasswordAuthResetPasswordPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public resetResetPasswordAuthResetPasswordPost(requestParameters: AuthApiResetResetPasswordAuthResetPasswordPostRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).resetResetPasswordAuthResetPasswordPost(requestParameters.bodyResetResetPasswordAuthResetPasswordPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify:Request-Token
     * @param {AuthApiVerifyRequestTokenAuthRequestVerifyTokenPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verifyRequestTokenAuthRequestVerifyTokenPost(requestParameters: AuthApiVerifyRequestTokenAuthRequestVerifyTokenPostRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).verifyRequestTokenAuthRequestVerifyTokenPost(requestParameters.bodyVerifyRequestTokenAuthRequestVerifyTokenPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify:Verify
     * @param {AuthApiVerifyVerifyAuthVerifyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verifyVerifyAuthVerifyPost(requestParameters: AuthApiVerifyVerifyAuthVerifyPostRequest, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).verifyVerifyAuthVerifyPost(requestParameters.bodyVerifyVerifyAuthVerifyPost, options).then((request) => request(this.axios, this.basePath));
    }
}

