import {
    Create,
    Datagrid,
    DateField,
    DateTimeInput, Edit,
    List, SaveButton,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput, Toolbar, useGetList, useUpdate
} from "react-admin";
import React from "react";
import {Grid} from "@mui/material";
import {useFormContext, useFormState} from "react-hook-form";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";


const TemplatesField = () => {
    const {data, isLoading} = useGetList(
        'sms_templates',
        {pagination: {page: 1, perPage: 100}}
    );
    return (
        <SelectInput
            source="template"
            choices={data}
            optionText="title"
            optionValue="id"
            isLoading={isLoading}
            fullWidth={true}
            required={true}
        />
    );
}


export const SMSCampaignList = (props: any) => (
    <List {...props} filters={[]} perPage={25}>
        <Datagrid rowClick="edit">
            <TextField source="title"/>
            <TextField source="recipients_count"/>
            <TextField source="sms_sent"/>
            <TextField source="sms_not_sent"/>
            <DateField source="datetime_start" showTime={true} label={"Datetime start (UTC)"}/>
        </Datagrid>
    </List>
);

const statusChoicesList = [
    {id: "new", name: "New"},
    {id: "run", name: "Run"},
    {id: "in_progress", name: "In Progress", disabled: true},
    {id: "done", name: "Done", disabled: true},
]

const FiltersField = () => {
    const {data, isLoading} = useGetList(
        'filters',
        {pagination: {page: 1, perPage: 100}}
    );
    return (
        <SelectInput
            name="filter"
            source="filter"
            choices={data}
            optionText="title"
            optionValue="id"
            isLoading={isLoading}
            fullWidth={true}
        />
    );
}

const SMSCampaignToolBar = () => {
    const [update] = useUpdate();
    const {getValues} = useFormContext();

    const handleSendToMyselfClick = (e: any) => {
        e.preventDefault(); // necessary to prevent default SaveButton submit logic
        const data = getValues();
        update(
            'sms/selftest',
            {id: data.id, data: data}
        );
    };

    const handleRunSMSCampaignClick = (e: any) => {
        e.preventDefault();
        const data = getValues();
        data.status = 'run'
        update(
            'sms',
            {data: data, id: data.id},
        );
    }
    const values = getValues()
    const formData = useFormState()
    const enableCondition = values.status === "new" && !formData.isDirty && values.recipients_count > 0
    return (
        <Toolbar>
            <SaveButton/>
            <SaveButton label="Send to myself" type="button" onClick={handleSendToMyselfClick}
                        alwaysEnable={enableCondition}
                        disabled={formData.isDirty}/>
            <SaveButton label="Run SMS Campaign" type="button" style={{marginLeft: "auto"}}
                        onClick={handleRunSMSCampaignClick} alwaysEnable={enableCondition}
                        disabled={formData.isDirty}
                        icon={<RocketLaunchIcon/>}/>
        </Toolbar>
    );
};


export const SMSCampaignCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm toolbar={<SMSCampaignToolBar/>}>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <TextInput name="title" source="title" fullWidth required/>
                        <TemplatesField/>
                        <SelectInput name="status" source="status" defaultValue="new" choices={statusChoicesList}
                                     disabled/>
                        <br/>
                        <FiltersField/>
                        <TextInput defaultValue="0" name="recipients_count" source="recipients_count" disabled/>
                        <br/>
                        <TextInput defaultValue="0" name="sms_sent" source="sms_sent" disabled/>
                        <br/>
                        <TextInput defaultValue="0" name="sms_not_sent" source="sms_not_sent"
                                   disabled/>
                        <br/>
                        <DateTimeInput name="datetime_start" source="datetime_start" disabled/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};


export const SMSCampaignEdit = (props: any) => {
    return (
        <Edit {...props}>
            <SimpleForm toolbar={<SMSCampaignToolBar/>}>
                <Grid container spacing={6}>
                    <Grid item xs={6}>
                        <TextInput name="title" source="title" fullWidth required/>
                        <TemplatesField/>
                        <SelectInput name="status" source="status" defaultValue="new" choices={statusChoicesList}
                                     disabled/>
                        <br/>
                        <FiltersField/>
                        <TextInput defaultValue="0" name="recipients_count" source="recipients_count" disabled/>
                        <br/>
                        <TextInput defaultValue="0" name="sms_sent" source="sms_sent" disabled/>
                        <br/>
                        <TextInput defaultValue="0" name="sms_not_sent" source="sms_not_sent"
                                   disabled/>
                        <br/>
                        <DateTimeInput name="datetime_start" source="datetime_start" disabled/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};

